import React, { useContext, useEffect, useState } from "react";
import { openPopupWidget } from "react-calendly";
import { useMutation } from "react-query";
import { getNotification } from "../../../../api/scenario";
import CallToActionButton from "../../../../components/CallToActionButton/call-to-action-button";
import { FinanceContext } from "../../../../context/finance-context";
import { useMyBrokerInfo } from "../../../../hooks/broker.hooks";
import { useLoanSubmission } from "../../../../hooks/loanApplication.hooks";
import { useMyInfo } from "../../../../hooks/user.hooks.ts";

import {
  Calendar,
  CircleCancel,
  CircleCheck,
  SendInformationIcon,
  Swap,
} from "../../../../svgs";

const SendInformation = () => {
  const { data: brokerInfo } = useMyBrokerInfo();
  const { data: userInfo } = useMyInfo();

  const { selectedScenarioId = "", selectedScenarioDetail = {} } = useContext(
    FinanceContext
  );
  const { opportunityId } = selectedScenarioDetail;
  const [notificationStatus, setNotificationStatus] = useState({
    status: "uncompleted",
  });
  const createLoanSubmissionMutation = useLoanSubmission(opportunityId);
  const handleClickCalendly = () => {
    openPopupWidget({ url: brokerInfo.calendlyUrl });
  };
  useEffect(() => {
    setNotificationStatus({
      status: "uncompleted",
    });
  }, [selectedScenarioId]);
  const sendNotification = useMutation(getNotification);

  const handleSendInformation = async () => {
    if (!opportunityId) {
      await createLoanSubmissionMutation.mutateAsync(selectedScenarioId);
    }
    await sendNotification.mutateAsync(selectedScenarioId, {
      onSuccess: () => {
        setNotificationStatus({ status: "success" });
      },
      onError: (item) => {
        const { data: { message = "" } = {} } = item;
        if (message === "Scenario is already submitted") {
          setNotificationStatus({ status: "existed" });
        } else {
          setNotificationStatus({ status: "failed" });
        }
      },
    });
  };

  const generateMessage = (status) => {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <div className="mr-3 font-size-lg font-weight-bold">
            {status === "success" && "Loan Submission Successfully Sent"}
            {status === "existed" && "Loan Submission Already Submitted"}
            {status === "failed" && "Loan Submission Failed"}
          </div>
          <div className="send-notification-icon">
            {(status === "success" || status === "existed") && <CircleCheck />}
            {status === "failed" && <CircleCancel />}
          </div>
        </div>
      </>
    );
  };

  const isOwner = selectedScenarioDetail?.mainUserId === userInfo?.id;

  return (
    <div className="pb-5">
      {notificationStatus.status !== "uncompleted" ? (
        generateMessage(notificationStatus.status)
      ) : (
        <div>
          {isOwner ? (
            <>
              <div className="font-weight-bold text-understroke mx-auto mb-4">
                Choose Option
              </div>
              <div className="send-notification-container">
                <CallToActionButton
                  title="Send & Call Me Asap"
                  icon={<SendInformationIcon />}
                  onClick={() => {
                    handleSendInformation();
                  }}
                />

                {Boolean(brokerInfo.calendlyUrl) && (
                  <>
                    <div className="swap-icon">
                      <Swap />
                    </div>
                    <CallToActionButton
                      title="Sent & Call Me later"
                      icon={<Calendar />}
                      onClick={() => {
                        handleClickCalendly();
                      }}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="text-center">
              Only owner can send information to broker
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SendInformation;
